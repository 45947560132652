/**
 *  This file is similar to provide-photo.js. Bugs found here likely have to be fixed there too. Possibility of commonizing these in the future.
 */

import '../components/loader';
import {urlToBucketKey as s3UrlToBucketKey} from "../s3";
import { v4 as uuid } from 'uuid';

// This is duplicated from our S3 service, we cannot reference that here without pulling lots of unrelated files into the clientside bundle
const maxFileSizeInMB = 20;

exports.uploadDocuments = function (
    numDocsRequired,
    stillUploading,
    error__none_selected,
    error__too_big,
    error__filetypes,
    error__unknown,
    error__abort,
    error__document_label,
    screen_reader_alert__file_added,
    screen_reader_alert__file_removed,
    continue_without_uploading_more_button_text,
    continue_button_text,
    error_phrase,
) {
    addScreenReaderFileStatusAlert();

    // the js code only runs while still uploading
    if (!stillUploading) {
        return;
    }

    const errorMessages = {
        error__none_selected,
        error__too_big,
        error__filetypes,
        error__unknown,
        error__abort,
        error__document_label
    };
    const spinner = new GOVUK.Loader();

    // containers
    const uploadFormId = 'upload-form';
    const uploadBoxId = 'upload-box';
    const uploadForm = document.getElementById(uploadFormId);

    // form inputs
    const labelSelectId = 'document-label-select';
    const fileUploadId = 'file-upload';

    const documentLabelSelect = document.getElementById(labelSelectId);
    const fileUpload = document.getElementById(fileUploadId);

    // error components
    const errors = {};
    const jsErrorSummary = document.querySelector(".govuk-error-summary")
    const jsErrorList = jsErrorSummary.querySelector("ul");
    const acceptedFileExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.pdf', '.heic', '.heif'];

    // submit button
    fileUpload.classList.add("hidden");
    fileUpload.addEventListener('change', uploadFile);
    const uploadButton = uploadForm.querySelector('button[name="upload-button"]');
    uploadButton.addEventListener('click', (event) => fileUpload.click());
    uploadButton.id = 'upload-button';
    uploadButton.type = "button";

    const continueForm = document.getElementById("continue-form");
    const nextButton = continueForm.querySelector('button[name="submit-button"]');
    enableNextButton();
    disableNextButtonOnRemoveFile();

    function uploadFile(event) {
        event.preventDefault();
        disableNextButton();

        clearErrors();
        uploadForm.classList.add("govuk-visually-hidden");
        spinner.init({
            container: "spinner-container-id", label: true
        });

        const documentLabelIsValid = validateDocumentLabel(documentLabelSelect.value);
        const file = fileUpload.files[0];
        const fileIsValid = validateFile(file);

        if (!documentLabelIsValid || !fileIsValid) {
            // Clear the selection so that the change event will fire when the user tries again
            resetFormInput();
            renderErrors();
            return false;
        }

        // testing browser support. if no support for the required js APIs
        // the form will just be posted naturally with no progress showing.
        var xhr = new XMLHttpRequest();
        if (!(xhr && ('upload' in xhr) && ('onprogress' in xhr.upload)) || !window.FormData) {
            uploadForm.submit();
            return;
        }

        xhr.upload.onprogress = progressHandler;
        xhr.onerror = errorHandler;
        xhr.onabort = abortHandler;
        xhr.onload = s3UploadCompleteHandler.bind(this, xhr, file.name);

        const formData = new FormData(uploadForm);
        // set to "" so not redirected automatically
        formData.set("success_action_redirect", "");
        xhr.open('POST', uploadForm.action);
        xhr.send(formData);

        return false;
    }

    function validateFile(file) {
        if (!file) {
            errors[uploadButton.id] = errorMessages.error__none_selected;
            return false;
        }
        if (file.size > maxFileSizeInMB * 1000000) {
            errors[uploadButton.id] = errorMessages.error__too_big;
            return false;
        }
        const nameLower = file.name.toLowerCase();
        if (!acceptedFileExtensions.find(ext => nameLower.endsWith(ext))) {
            errors[uploadButton.id] = errorMessages.error__filetypes;
            return false;
        }
        return true;
    }

    function validateDocumentLabel(documentLabel) {
        if (!documentLabel) {
            errors[labelSelectId] = errorMessages.error__document_label;
            return false;
        }
        return true;
    }

    function progressHandler(e) {
        var progress = Math.floor((e.loaded / e.total) * 100);
        spinner.updateMessage(`Uploading... ${progress}%`);
        spinner.updateProgress(progress);
    }

    // Called when the upload to S3 has completed
    function s3UploadCompleteHandler(uploadXhr, fileName) {
        try {
            spinner.updateMessage(`Processing`);
            if (uploadXhr.status !== 204) {
                // 'xhr.onerror' is only called if there's an error at the network level.
                // If the error only exists at the application level (e.g. an HTTP error code is sent), it will not be called.
                // So we need to check the status code here in order to show a validation message if s3 reject the file.
                throw new Error("Error status from S3: " + uploadXhr.status);
            }

            // Tell the backend the name of the file that was just uploaded,
            // so the session state can be updated.
            // This allows us to avoid S3 "list" ops, which don't scale to high load.
            //
            // If the client browser fails or goes offline between the above op and
            // this then the image info will not get added to the session, and they
            // will need to upload it again

            // S3 can change the provided filename, for example spaces are trimmed.
            // We therefore get the final bucket + key info from the Location header
            const s3Location = uploadXhr.getResponseHeader("location");
            const bucketKey = s3UrlToBucketKey(s3Location);

            // browser xhr support was already tested above
            var xhr = new XMLHttpRequest();

            xhr.onload = sessionUpdateCompleteHandler.bind(this, xhr);
            xhr.onerror = (e) => errorHandler(e);
            xhr.onabort = () => abortHandler();

            xhr.open('POST', document.location);
            const formData = {
                "s3-upload-complete": "true",
                bucket: bucketKey.bucket,
                key: bucketKey.key
            };
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            // ask the browser to parse the html response into a DOM
            xhr.responseType = "document";
            xhr.send(new URLSearchParams(Object.entries(formData)).toString());

        } catch (e) {
            errorHandler(e)
        }
    }

    // Called when the after-upload session update XHR has completed
    function sessionUpdateCompleteHandler(xhr) {
        try {
            if (xhr.status !== 200) {
                // 'xhr.onerror' is only called if there's an error at the network level.
                // If the error only exists at the application level (e.g. an HTTP error code is sent), it will not be called.
                // So we need to check the status code here in order to show a validation message if s3 reject the file.
                throw new Error("Error status from backend: " + xhr.status);
            }

            const newHtml = xhr.responseXML;
            if (!newHtml) {
                throw new Error("responseXML null. Not supported browser?");
            }
            const numberOfDocsUploaded = document.getElementsByClassName("document-row").length;
            const uploadingLastDoc = numberOfDocsUploaded === (numDocsRequired - 1);
            const uploadContainer = document.getElementById("doc-upload-container");
            uploadContainer.parentNode.insertBefore(newHtml.body, uploadContainer);
            screenReaderFileAlert(screen_reader_alert__file_added);
            // Update the UUID in the prepost location in case the user tries a file of the same name and label
            replaceUUIDInDocumentLabelSelectOptionsForNextUpload();
            // Clear the selection so that the change event will fire if the user tries a file of the same name
            resetFormInput();
            spinner.stop();
            enableNextButton();
            disableNextButtonOnRemoveFile();
            const submitButton = document.querySelector('button[name="submit-button"]');
            if (!uploadingLastDoc) {
                uploadForm.classList.remove("govuk-visually-hidden");
                submitButton.textContent = continue_without_uploading_more_button_text;
            } else {
                uploadContainer.classList.add("govuk-visually-hidden");
                submitButton.textContent = continue_button_text;
            }
        } catch (e) {
            errorHandler(e)
        }
    }

    function errorHandler(data) {
        console.error(data);
        errors[uploadBoxId] = errorMessages.error__unknown;
        renderErrors();
    }

    function abortHandler() {
        errors[uploadBoxId] = errorMessages.error__abort;
        renderErrors();
    }

    function renderErrors() {
        spinner.stop();
        enableNextButton();
        uploadForm.classList.remove("govuk-visually-hidden");
        updateTitleIfNotAlreadyInError();

        Object.entries(errors).forEach(([fieldId, message]) => {
            addErrorSummaryItem(fieldId, message);
            addErrorToField(fieldId, message);
        });

        jsErrorSummary.style.display = 'block';
        jsErrorSummary.focus();
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    function updateTitleIfNotAlreadyInError() {
        const title = document.title;
        if (title && !title.startsWith(`${error_phrase}:`)) {
            document.title = `${error_phrase}: ${title}`;
        }
    }

    function clearErrors() {
        // Some pages have an error summary which is hidden by default, some don't. Setting to none works in both cases.
        jsErrorSummary.style.display = 'none';
        Object.keys(errors).forEach(fieldId => {
            jsErrorList.removeChild(document.getElementById(fieldId + "--top-error"))
            removeErrorFromField(fieldId);
            delete errors[fieldId];
        });
    }

    function addErrorSummaryItem(fieldId, errorMessage) {
        const listItem = document.createElement("li");
        const anchor = listItem.appendChild(document.createElement("a"));
        anchor.href = "#" + fieldId;
        anchor.textContent = errorMessage;

        listItem.id = fieldId + "--top-error"
        jsErrorList.appendChild(listItem);
    }

    function addErrorToField(fieldId, errorMessage) {
        const field = document.getElementById(fieldId);
        const container = field.parentNode;
        const spanId = field.id + "--span-error";

        let span = document.getElementById(spanId);
        if (span && span.lastChild) {
            span.lastChild.textContent = errorMessage;
        } else {
            span = document.createElement("span");
            span.id = spanId;
            span.className = 'govuk-error-message';
            var hiddenSpan = span.appendChild(document.createElement("span"));
            hiddenSpan.className = "govuk-visually-hidden";
            hiddenSpan.textContent = `${error_phrase}:`;
            var errorSpan = span.appendChild(document.createElement("span"));
            errorSpan.textContent = errorMessage;
        }

        container.insertBefore(span, field);
        container.classList.add("govuk-form-group--error");
    }

    function removeErrorFromField(fieldId) {
        const field = document.getElementById(fieldId);
        const container = field.parentNode;
        const spanId = field.id + "--span-error";
        const span = document.getElementById(spanId);

        if (span) {
            container.removeChild(span);
            container.classList.remove("govuk-form-group--error");
        }
    }

    function addScreenReaderFileStatusAlert() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        if (urlSearchParams.get("file-removed") === "true") {
            screenReaderFileAlert(screen_reader_alert__file_removed);
        }

        // no "file-added" case, as that's either handled directly in js,
        // see screen_reader_alert__file_added above, or we're no-js
    }

    function screenReaderFileAlert(alertMessage) {
        const alert = document.getElementById("hidden-alert-container");
        alert.innerHTML = alertMessage;
    }

    // We replace UUIDs to ensure that documents with the same name and label can be uploaded correctly
    // For non-JS users this is done in generateUniqueDocumentKeyPrefix in documentHelper.ts
    function replaceUUIDInDocumentLabelSelectOptionsForNextUpload() {
        const selectOptionsElt = document.getElementById("document-label-select").options;
        const newUUID = uuid();
        for (let i = 0; i < selectOptionsElt.length; i++) {
            const option = selectOptionsElt[i];
            option.value = replaceUUIDInPresignedKey(selectOptionsElt[i].value, newUUID);
        }
    }

    function replaceUUIDInPresignedKey(presignedKey, newUUID) {
        // See pattern from arrayToSelectValuesWithPrefixForS3 in templating.ts
        // The key here will look like {session ID}/{document class}/{UUID}/{document label}/{file name}
        return presignedKey.replace(/\/[a-z0-9\-]+(\/[a-z0-9\-]+\/\$\{filename})/, `/${newUUID}$1`);
    }

    function resetFormInput() {
        fileUpload.value = "";
    }

    function enableNextButton() {
        nextButton.removeAttribute("disabled");
        nextButton.removeAttribute("aria-disabled");
    }

    function disableNextButton() {
        nextButton.setAttribute("disabled", "");
        nextButton.setAttribute("aria-disabled", true);
    }

    function disableNextButtonOnRemoveFile() {
        const removeFileButtons = document.getElementsByName('remove-file-button');
        removeFileButtons && removeFileButtons.forEach(button => {
            button.addEventListener('click', () => {
                disableNextButton();
            });
        });
    }
};